import React from "react";
import "./blogs.css";
const Blogs = () => {



  return (
    <>
      <section className="blogs container">
        <h1 className="text-center title ">
          <span>Blogs</span>
        </h1>
        <script src="https://static.elfsight.com/platform/platform.js" async></script>
        <div class="elfsight-app-af200899-c2c6-4263-b2c7-ef1163330cbf" data-elfsight-app-lazy></div>
      </section>
    </>
  );
};

export default Blogs;
