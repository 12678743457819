import React, { useState } from "react";
import { useForm } from "react-hook-form";
import SelectBoxes from "./SelectBoxes";
import "./hiring.css";
import axios from "axios";
import ProcessSpinner from "../../Component/Spinners/ProcessSpinner";
import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";

const Hiring = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendmail, setSendmail] = useState(false);
  const [isHire, setIsHire] = useState(false); // Add isHire state

  var residence = [
    { name: "Hosteller", value: "Hosteller" },
    { name: "Day Scholar", value: "Day Scholar" },
  ];
  const workshop = watch("workshop");

  const sumbit_data = (data) => {
    setLoad(true);
    setLoading(true);
    axios({
      method: "POST",
      url: "https://or3hs0ffq3.execute-api.ap-south-1.amazonaws.com/dev/selection",
      data,
    }).then(() => {
      setSendmail(true);
      setLoading(false);
      // The first API request is successful, now send data to the second API
      axios({
        method: "POST",
        url: "https://name-9w3b.onrender.com/hiring",
        data: {
          fullName: data.fullname,
          email: data.emailId,
        },
      })
        .then(() => {
          setSendmail(false);
          setLoad(false);
          toast.success("Successfully Updated!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          toast.error("Something Went Wrong, Try Again!");
        });
    });
  };

  return (
    <section className="container hiring--process">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <h1>Loading....</h1>
            <PropagateLoader color="#0fb8dd" cssOverride={{}} size={29} />
          </div>
        </div>
      )}

      {sendmail && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <h1>We are Sending you a Mail....</h1>
            <PropagateLoader color="#0fb8dd" cssOverride={{}} size={29} />
          </div>
        </div>
      )}


      <article style={{ display: "flex", justifyContent: "center" }}>
        {isHire ? (
          <div className="container hiring--process">
            <h1 className="text-center">Selection Process</h1>
            <main
              className="card shadow-lg modal--card"
              style={{ borderRadius: 20 }}
            >
              <article className="modal-body mt-2">
                <p style={{ fontSize: 18, marginBottom: 40 }}>
                  Please fill up all the details in order to proceed...
                </p>
                <form className={`contact_card`} onSubmit={handleSubmit(sumbit_data)}>
                  <section className="row">
                    <div className="col-md-6 mb-4">
                      <label className="form-label" id="view-ticket">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("fullname", { required: true })}
                      />
                      <p>
                        {errors.fullname && (
                          <span className="text-danger">This field is required</span>
                        )}
                      </p>
                    </div>
                    <div className="col-md-6 mb-4">
                      <label className="form-label">Roll No</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("rollno", {
                          required: true,
                          pattern: /^\d{9}$/,
                        })}
                      />
                      <p>
                        {errors.rollno ? (
                          errors.rollno?.type === "pattern" ? (
                            <span className="text-danger">must be of 9 digits</span>
                          ) : (
                            <span className="text-danger">This field is required</span>
                          )
                        ) : null}
                      </p>
                    </div>
                  </section>

                  {/* Add more fields as required */}

                  <section className="row">
                    <div className="select--box-3 col-md-6 mb-4">
                      <label className="form-label">Residence</label>
                      <select className="form-control" {...register("residence", { required: true })}>
                        <option value="">Select</option>
                        {residence.map((item, i) => {
                          const { name, value } = item;
                          return <option key={i} value={value}>{name}</option>;
                        })}
                      </select>
                      {errors.residence && <span className="text-danger">This field is required</span>}
                    </div>
                  </section>

                  {workshop === "Yes" && (
                    <div className="col-md-12 mb-4">
                      <label className="form-label">What you learned from that Workshop?</label>
                      <textarea
                        type="text"
                        className="form-control"
                        {...register("workshop_outcome", { required: true })}
                      />
                      <p>
                        {errors.workshop_outcome && (
                          <span className="text-danger">This field is required</span>
                        )}
                      </p>
                    </div>
                  )}

                  <article style={{ float: "right", display: "flex", gap: "1em", marginTop: "2%" }}>
                    <button className="btn btn-success" style={{ width: "150px" }}>
                      {load ? <ProcessSpinner /> : "Submit"}
                    </button>
                    <button type="button" onClick={() => window.location.reload()} className="btn btn-danger">
                      Close
                    </button>
                  </article>
                </form>
              </article>
              <br />
            </main>
          </div>
        ) : (
          <div>
            <h2>We are not hiring now.</h2>
            <p>Please check back later for updates on the recruitment process.</p>
          </div>
        )}
      </article>
    </section>
  );
};

export default Hiring;
